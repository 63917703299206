import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from './auth.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {

  constructor(private authService: AuthService) { }
  // constructor() { }

  ngOnInit() {
  }

  onSubmitAuthentication(authForm: NgForm) {
    const username = authForm.value.username;
    const password = authForm.value.password;

    const authResult: boolean = this.authService.signin(username, password);
  }

}
