import { MaritimeObservationPictureModel } from "./MaritimeObservationPicture.Model";
import { MaritimeObservationVideoModel } from "./MaritimeObservationVideo.Model";

export class MaritimeObservationModel
{
    Id: string;
    DateTimeZ: Date;
    Cap : number;
    Speed:number;
    AIS:boolean;
    Latitude:number;
    Longitude:number;
    Observation:string;
    FlagHarbour:string;
    MARITIME_Id:string;
    MARITIME_PICTUREModelSet:MaritimeObservationPictureModel[];
    MARITIME_VIDEOModelSet:MaritimeObservationVideoModel[];

    constructor() {}
}
