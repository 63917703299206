import { Component, OnInit, Input } from '@angular/core';
import { MaritimeModel } from '../Models/Maritime.Model';
import { MaritimeObservationModel } from '../Models/MaritimeObservation.Model';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-maritime-observations',
  templateUrl: './maritime-observations.component.html',
  styleUrls: ['./maritime-observations.component.css']
})
export class MaritimeObservationsComponent implements OnInit {

  constructor() { }

  maritimeSelected? : MaritimeModel;
  observations? : MaritimeObservationModel[];

  
  @Input('selectMaritime') 
  set selectMaritime(value: MaritimeModel) {
    if(!isNullOrUndefined(value)){
      this.maritimeSelected = value;
      this.observations = value.MARITIME_OBSERVATIONSet;
    }
    else{
      this.maritimeSelected = null;
      this.observations = [];
    }
  }

  ngOnInit() {
  }

  onObservationAdded(evt:MaritimeObservationModel){
    console.log('onObservationAdded',evt);
    this.observations.unshift(evt);
  }

  onObservationDeleted(evt:MaritimeObservationModel){
    console.log('onObservationDeleted',evt);
    this.observations = this.observations.filter(n=>n.Id != evt.Id);
  }

}
