import { Component, OnInit } from '@angular/core';
import { Validators, FormControl } from '@angular/forms';
import { MaritimeService } from '../maritime.service';
import { MatDialogRef } from '@angular/material';
import { MaritimeClassificationModel } from '../Models/MaritimeClassification.Model';

@Component({
  selector: 'app-maritime-ajouter-classif',
  templateUrl: './maritime-ajouter-classif.component.html',
  styleUrls: ['./maritime-ajouter-classif.component.css']
})

export class MaritimeAjouterClassifComponent implements OnInit {

  classificationText = "";

  FormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(3),
    Validators.maxLength(50)
  ]);

  constructor(public marServ : MaritimeService,public dialogRef: MatDialogRef<MaritimeAjouterClassifComponent>) { }

  ngOnInit() {
  }

  creer(){
    if(!this.FormControl.hasError('required'))
    { 
      console.log('postNewClassification before',this.FormControl.value);
      this.marServ.postNewClassification(this.FormControl.value).subscribe(
        (value: MaritimeClassificationModel) => {
          console.log('postNewClassification after', value);
          if(value){
            this.dialogRef.close(true);
          }
          else{
            this.dialogRef.close(false);
          }
        }
      );
    }
  } 

}
