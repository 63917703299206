import { Component, OnInit, Input, Inject } from '@angular/core';
import { MaritimeObservationModel } from '../Models/MaritimeObservation.Model';
import { MaritimeService } from '../maritime.service';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-maritime-ajouter-observation',
  templateUrl: './maritime-ajouter-observation.component.html',
  styleUrls: ['./maritime-ajouter-observation.component.css']
})
export class MaritimeAjouterObservationComponent implements OnInit {


  _observationUpdate : MaritimeObservationModel;

  @Input('observationUpdate') 
  set observationUpdate(value: MaritimeObservationModel) {
    this._observationUpdate =  value;
    this.userForm.value.ControlPavillon = value.FlagHarbour;

  }

  latHemi : string;
  pavillonSelected : string;
  allPavillons : string[];

  titre : string;

  userForm = new FormGroup({
    ControlPavillon : new FormControl('',Validators.required),
    ControlDate : new FormControl(new Date(), [Validators.required]),
    ControlDegresLat : new FormControl(0, [Validators.required,Validators.min(0),,Validators.max(90)]),
    ControlMinuteLat : new FormControl(0, [Validators.required,Validators.min(0),Validators.max(60)]),
    ControlSecondeLat : new FormControl(0, [Validators.required,Validators.min(0),Validators.max(60)]),
    ControlDegresLon : new FormControl(0, [Validators.required,Validators.min(0),,Validators.max(180)]),
    ControlMinuteLon : new FormControl(0, [Validators.required,Validators.min(0),Validators.max(60)]),
    ControlSecondeLon : new FormControl(0, [Validators.required,Validators.min(0),Validators.max(60)]),
    ControlHemiLat : new FormControl('', [Validators.required]),
    ControlEstOuestLon : new FormControl('', [Validators.required]),
    ControlCap : new FormControl(0, [Validators.required,Validators.min(0),Validators.max(360)]),
    ControlSpeed : new FormControl(0, [Validators.required,Validators.min(0),Validators.max(100)]),
    ControlAis : new FormControl(false, Validators.required),
    ControlCommentaire : new FormControl('',Validators.maxLength(200)),
  });

  constructor(public snackBar: MatSnackBar
              ,public marServ : MaritimeService
              ,public dialogRef: MatDialogRef<MaritimeAjouterObservationComponent>
              ,@Inject(MAT_DIALOG_DATA) public data: any) 
  { 
    console.log('constructor',data.observation);
    if(!isNullOrUndefined(data.observation)){
      //Modification
      this.titre = "Modifier l'observation";

      this._observationUpdate = data.observation;

      let latDegMinSec : number[] =  marServ.ConvertCoordDecimalToDegMinSec(this._observationUpdate.Latitude);
      let lonDegMinSec : number[] =  marServ.ConvertCoordDecimalToDegMinSec(this._observationUpdate.Longitude);

      this.userForm.patchValue({
        ControlPavillon: this._observationUpdate.FlagHarbour,
        ControlDate : this._observationUpdate.DateTimeZ,
        ControlDegresLat : latDegMinSec[0],
        ControlMinuteLat : latDegMinSec[1],
        ControlSecondeLat : latDegMinSec[2],
        ControlDegresLon : lonDegMinSec[0],
        ControlMinuteLon : lonDegMinSec[1],
        ControlSecondeLon : lonDegMinSec[2],
        ControlHemiLat : marServ.getNordSudByDegDecimaux(this._observationUpdate.Latitude),
        ControlEstOuestLon : marServ.getEstOuestByDegDecimaux(this._observationUpdate.Longitude),
        ControlCap : this._observationUpdate.Cap,
        ControlSpeed : this._observationUpdate.Speed,
        ControlAis : this._observationUpdate.AIS,
        ControlCommentaire : this._observationUpdate.Observation
      });
    }
    else{
      this.titre = "Ajouter une observation";
    }
  }

  ngOnInit() {
    this.marServ.getAllPavillons().subscribe(
      (value: string[]) => {
        console.log('getAllPavillons', value);
        this.allPavillons = value;
      }
    );
  }

  onFormSubmit(){
    if (this.userForm.valid) {
      console.log("valid",this.userForm.value);

      let obs = new MaritimeObservationModel();
      obs.AIS = this.userForm.value.ControlAis;
      obs.Cap = this.userForm.value.ControlCap;
      obs.DateTimeZ = this.userForm.value.ControlDate;
      obs.FlagHarbour = this.userForm.value.ControlPavillon;
      obs.Latitude = this.marServ.ConvertCoordDegMinSecToDecimal(this.userForm.value.ControlDegresLat, this.userForm.value.ControlMinuteLat, this.userForm.value.ControlSecondeLat, this.userForm.value.ControlHemiLat);
      obs.Longitude = this.marServ.ConvertCoordDegMinSecToDecimal(this.userForm.value.ControlDegresLon, this.userForm.value.ControlMinuteLon, this.userForm.value.ControlSecondeLon, this.userForm.value.ControlEstOuestLon);
      obs.MARITIME_Id = '';
      obs.Observation = this.userForm.value.ControlCommentaire;
      obs.Speed = this.userForm.value.ControlSpeed;

      if(!isNullOrUndefined(this._observationUpdate)){
        //Modification
        obs.Id = this._observationUpdate.Id;
      }

      this.dialogRef.close(obs);

    } else {
      console.log("pas valid",this.userForm.value); 
      this.snackBar.open("Erreur ", "Complétez le formulaire", {
        duration: 3000,
      });
    }
  }

}
