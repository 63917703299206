export class MaritimeSearchModel
{
    ShipName : string
    MMSI : string
    IMO  : string
    DateTimeStart : Date
    DateTimeEnd : Date

    constructor() {}
}

