import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {FormControl} from '@angular/forms';
import { MaritimeSearchModel } from '../Models/MaritimeSearch.Model';
import { MaritimeClassificationModel } from '../Models/MaritimeClassification.Model';
import { MatDialog, MatSnackBar } from '@angular/material';
import { MaritimeAjouterClassifComponent } from '../maritime-ajouter-classif/maritime-ajouter-classif.component';
import { MaritimeService } from '../maritime.service';
import { MaritimeLightModel } from '../Models/MaritimeLight.Model';
import { MaritimeModel } from '../Models/Maritime.Model';

@Component({
  selector: 'app-maritime-recherche',
  templateUrl: './maritime-recherche.component.html',
  styleUrls: ['./maritime-recherche.component.css']
})

export class MaritimeRechercheComponent implements OnInit {
  
  ControlShipName = new FormControl();
  ControlClassification = new FormControl();
  ControlMMSI = new FormControl();
  ControlIMO = new FormControl();

  martimeSearchs : MaritimeLightModel[];
  maritimeClassifications : MaritimeClassificationModel[];

  maritimeSelected : MaritimeModel;

  @Output() onMaritimeSelected = new EventEmitter<MaritimeModel>();
  @Output() onMaritimeDeSelected = new EventEmitter<boolean>();
  
  @Input('selectMaritime') 
  set selectMaritime(value: MaritimeModel) {
    this.maritimeSelected =  value;
  }
  
  constructor(public dialog: MatDialog, public marServ : MaritimeService,public snackBar: MatSnackBar) { }

  ngOnInit() {

    this.maritimeSelected = new MaritimeModel();

    this.marServ.getAllClassifications().subscribe(
      (value: MaritimeClassificationModel[]) => {
        console.log('getAllClassifications', value);
        this.maritimeClassifications = value;
      }
    );
  }

  addClassification(){
    let dialogRef = this.dialog.open(MaritimeAjouterClassifComponent, {
      width: '300px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('MaritimeAjouterClassifComponent closed');
      }
    })
  }

  onSearchChange(event){
    console.log('onSearchChange',event.target.value);

    let textSaisi : string = event.target.value;
    let name = event.target.name;

    let shipName = "";
    let MMSI = "";
    let IMO = "";

    if(textSaisi.length >= 3){
      if(name == "ShipName"){
        shipName = textSaisi;
        MMSI = "";
        IMO = "";
      }
      else if(name == "MMSI"){
        shipName = "";
        MMSI = textSaisi;
        IMO = "";
      }
      else if(name == "IMO"){
        shipName = "";
        MMSI = "";
        IMO = textSaisi;
      }

      this.marServ.searchMaritimeStartWith(shipName,MMSI,IMO,null,null).subscribe(
        (value: MaritimeLightModel[]) => {
          console.log('searchMaritimeStartWith', value);
          this.martimeSearchs = value;
        }
      );

    }
  }


  onChangeShip(martimeSearch){
      console.log(martimeSearch.option.id);

      this.marServ.getMaritimeById(martimeSearch.option.id).subscribe(
        (value: MaritimeModel) => {
          console.log('MaritimeModel', value);
          this.maritimeSelected = value;

          this.onMaritimeSelected.emit(this.maritimeSelected);
        },
        error => {
          console.log(error);
      }
      );
    }

  onDeselect(){
    //this.maritimeSelected.ShipName='';
    this.ControlShipName.reset();
    this.ControlIMO.reset();
    this.ControlMMSI.reset();
    this.ControlClassification.reset();
    this.martimeSearchs=[]
    this.onMaritimeDeSelected.emit(true);
  }

  addShip(){
    console.log('addShip',this.ControlClassification);
    this.marServ.PostNewShip(this.ControlShipName.value,this.ControlMMSI.value,this.ControlIMO.value,this.ControlClassification.value.Id).subscribe(
      (value: MaritimeLightModel) => {
        console.log('MaritimeLightModel', value);
        this.maritimeSelected = this.marServ.FromMartimeModelLightToMaritimeModel(value);

        this.openSnackBar("Nouveau bateau ", "Créé");
      },
      error => {
        console.log(error);
        this.openSnackBar("Nouveau bateau ", "Erreur !"+error._body);
    }
    );

  }

  displayFn(classif?: MaritimeClassificationModel): string | undefined {
    console.log('displayFn',classif);
    return classif ? classif.ClassificationName : undefined;
  }

  clear(){
    console.log('clear',this.ControlShipName);
    //this.ControlShipName.value = "";
    console.log('clear',this.maritimeSelected);
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }
}
