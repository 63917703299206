import { MaritimeObservationModel } from "./MaritimeObservation.Model";
import { MaritimeClassificationModel } from "./MaritimeClassification.Model";

export class MaritimeModel
{
    Id: string;
    ShipName: string;
    MMSI: string;
    IMO: string;
    DateTimeCreation: Date;
    DateTimeUpdate: Date;
    MARITIME_CLASSIFICATION_Id: string;
    MARITIME_CLASSIFICATION_Name: string;
    MARITIME_CLASSIFICATIONSet: MaritimeClassificationModel;
    MARITIME_OBSERVATIONSet: MaritimeObservationModel[];

    constructor() {}
}

