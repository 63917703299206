export class MaritimeLightModel
{
    Id: string;
    ShipName: string;
    MMSI: string;
    IMO: string;
    MARITIME_CLASSIFICATION_Id: string;
    MARITIME_CLASSIFICATION_Name: string;

    constructor() {}
}

