import { Component, OnInit, ViewChild } from '@angular/core';
import { MaritimeModel } from '../Models/Maritime.Model';
import { MaritimeObservationsComponent } from '../maritime-observations/maritime-observations.component';

@Component({
  selector: 'app-maritime-container',
  templateUrl: './maritime-container.component.html',
  styleUrls: ['./maritime-container.component.css']
})
export class MaritimeContainerComponent implements OnInit {

  constructor() { }

  @ViewChild(MaritimeObservationsComponent)
  private maritimeObservationsComponent: MaritimeObservationsComponent;

  ngOnInit() {
  }

  onMaritimeSelected(maritimeModel : MaritimeModel){
    console.log('container',maritimeModel);

    this.maritimeObservationsComponent.selectMaritime = maritimeModel;
  }

  onMaritimeDeSelected(retour : boolean){
    console.log('onMaritimeDeSelected');

    this.maritimeObservationsComponent.selectMaritime = null;
  }

}
