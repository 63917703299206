import { Component, OnInit } from '@angular/core';
import { AuthService } from '../signin/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  
  isAuthenticated = false;

  constructor(private authService: AuthService) { }

  ngOnInit() {
    this.isAuthenticated = this.authService.isAuthenticated();
    this.authService.tokenChanged.subscribe(() =>
    {
      this.isAuthenticated = this.authService.isAuthenticated();
    });
    console.log("ngOnInit home",this.isAuthenticated);
  }

}
