import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-maritime-video',
  templateUrl: './maritime-video.component.html',
  styleUrls: ['./maritime-video.component.css']
})
export class MaritimeVideoComponent implements OnInit {

  videoUrl : string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.videoUrl = data.url;
    console.log('MaritimeVideoComponent url',this.videoUrl);
   }

  ngOnInit() {
  }

  DownloadVideo(){
    window.open(this.videoUrl, "_blank");
  }
}
