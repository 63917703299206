import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { MaritimeService } from '../maritime.service';
import { MaritimeAjouterObservationComponent } from '../maritime-ajouter-observation/maritime-ajouter-observation.component';
import { MaritimeModel } from '../Models/Maritime.Model';
import { isNullOrUndefined } from 'util';
import { Action } from 'rxjs/internal/scheduler/Action';
import { MaritimeObservationModel } from '../Models/MaritimeObservation.Model';

@Component({
  selector: 'app-maritime-donnees-creer',
  templateUrl: './maritime-donnees-creer.component.html',
  styleUrls: ['./maritime-donnees-creer.component.css']
})
export class MaritimeDonneesCreerComponent implements OnInit {

  constructor(public dialog: MatDialog, public marServ : MaritimeService,public snackBar: MatSnackBar) { }

  @Input()
  maritimeSelected : MaritimeModel;

  @Output() 
  observationAdded = new EventEmitter<MaritimeObservationModel>();


  ngOnInit() {

  }

  addObservation(){
    if(!isNullOrUndefined(this.maritimeSelected)){
      let dialogRef = this.dialog.open(MaritimeAjouterObservationComponent, {
        width: '80%',
        data: {maritimeSelected : this.maritimeSelected}
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          let obs = result as MaritimeObservationModel;
          console.log('MaritimeAjouterClassifComponent closed',obs,this.maritimeSelected);
          obs.MARITIME_Id = this.maritimeSelected.Id; //Ajout de l'ID que l'on a pas dans la popup

          this.marServ.PostNewObservation(obs).subscribe(
            (value: MaritimeObservationModel) => {
              console.log('MaritimeObservationModel', value);
              this.observationAdded.emit(value);
              this.snackBar.open("Observation ajoutée !", "", {
                duration: 3000,
              });
            }
          );
        }
      })
    }
    else{
      this.snackBar.open("Aucun bateau n'est sélectionné !", "", {
        duration: 3000,
      });
    }
  }

}
