import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MaritimePavillonModel } from '../Models/MaritimePavillon.Model';
import { MaritimeObservationModel } from '../Models/MaritimeObservation.Model';
import { MatDialog, MatSnackBar } from '@angular/material';
import { MaritimeAjouterObservationComponent } from '../maritime-ajouter-observation/maritime-ajouter-observation.component';
import { MaritimeService } from '../maritime.service';
import { ConfirmYesNoComponent } from '../../common/confirm-yes-no/confirm-yes-no.component';

@Component({
  selector: 'app-maritime-donnees',
  templateUrl: './maritime-donnees.component.html',
  styleUrls: ['./maritime-donnees.component.css']
})
export class MaritimeDonneesComponent implements OnInit {

  @Input()
  observation : MaritimeObservationModel;

  @Output() observationDeleted = new EventEmitter<MaritimeObservationModel>();

  constructor(public dialog: MatDialog,public marServ : MaritimeService,public snackBar: MatSnackBar) { }

  ngOnInit() { 
    console.log('observation',this.observation);
  }

  getDegresLat(){
    return this.marServ.ConvertCoordDecimalToDegMinSec(this.observation.Latitude)[0];
  }
  getMinuteLat(){
    return this.marServ.ConvertCoordDecimalToDegMinSec(this.observation.Latitude)[1];
  }
  getSecondeLat(){
    return this.marServ.ConvertCoordDecimalToDegMinSec(this.observation.Latitude)[2];
  }
  getNordSudLat(){
    return this.marServ.getNordSudByDegDecimaux(this.observation.Latitude)[2];
  }
  getDegresLon(){
    return this.marServ.ConvertCoordDecimalToDegMinSec(this.observation.Longitude)[0];
  }
  getMinuteLon(){
    return this.marServ.ConvertCoordDecimalToDegMinSec(this.observation.Longitude)[1];
  }
  getSecondeLon(){
    return this.marServ.ConvertCoordDecimalToDegMinSec(this.observation.Longitude)[2];
  }
  getEstOuestLon(){
    return this.marServ.getEstOuestByDegDecimaux(this.observation.Latitude)[2];
  }

  modifier() {

      let dialogRef = this.dialog.open(MaritimeAjouterObservationComponent, {
        width: '80%',
        data: {observation:this.observation}
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          let obs = result as MaritimeObservationModel;
          console.log('MaritimeAjouterClassifComponent modifier closed',obs);

          this.marServ.UpdateObservation(obs).subscribe(
            (value: MaritimeObservationModel) => {
              console.log('MaritimeObservationModel', value);
              this.observation = obs;
              this.snackBar.open("Observation modifiée !", "", {
                duration: 3000,
              });
            }
          );
        }
      })

  }

  supprimer(){
    let dialogRef = this.dialog.open(ConfirmYesNoComponent, {
      width: '300px',
      data: {message : "Etes-vous sûr de vouloir supprimer cette observation ?"}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.marServ.DeleteObservation(this.observation).subscribe(
          (value: boolean) => {
            console.log('supprimer observation', value);
            this.observationDeleted.emit(this.observation);
            this.snackBar.open("Observation supprimée !", "", {
              duration: 3000,
            });
          }
        );
      }
      else{
        this.snackBar.open("Opération annulée !", "", {
          duration: 3000,
        });
      }
    });

  }
}