import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-maritime-photo',
  templateUrl: './maritime-photo.component.html',
  styleUrls: ['./maritime-photo.component.css']
})
export class MaritimePhotoComponent implements OnInit {

  pictureUrl : string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.pictureUrl = data.url;
    console.log('MaritimePhotoComponent url',this.pictureUrl);
   }

  ngOnInit() { 
  }

}
