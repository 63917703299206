import { Injectable } from "@angular/core";
import { Http, Response, RequestOptions, Headers } from "@angular/http";
import { map } from 'rxjs/operators';
import { environment } from "../../environments/environment.prod";
import { MaritimeModel } from "./Models/Maritime.Model";
import { MaritimeSearchModel } from "./Models/MaritimeSearch.Model";
import { MaritimeLightModel } from "./Models/MaritimeLight.Model";
import { MaritimeClassificationModel } from "./Models/MaritimeClassification.Model";
import { MaritimeObservationModel } from './Models/MaritimeObservation.Model';
import { MaritimeObservationPictureModel } from "./Models/MaritimeObservationPicture.Model";
import { MaritimeObservationVideoModel } from "./Models/MaritimeObservationVideo.Model";

@Injectable()
export class MaritimeService {

    constructor(private http: Http) { }

    searchMaritimeStartWith(ShipName : string, MMSI : string, IMO  : string, DateTimeStart? : Date, DateTimeEnd? : Date) {
        
        let url = environment.url_base + 'api/Maritime/SearchMaritime';

        let searchModel = new MaritimeSearchModel();
        searchModel.ShipName = ShipName;
        searchModel.MMSI = MMSI;
        searchModel.IMO = IMO;
        searchModel.DateTimeStart = DateTimeStart;
        searchModel.DateTimeEnd = DateTimeEnd;
        const searchModelAsString:String = JSON.stringify(searchModel);

        console.log('searchMaritime Url : ' + url);
        
        const header = new Headers();
        header.append('Content-Type', 'application/json');
        return this.http.post(url, searchModelAsString, { headers: header }).pipe(map(
            (response: Response) => {
                const MaritimeModels: MaritimeLightModel[] = response.json();
                return MaritimeModels;
            }
        ));
    }

    getMaritimeById(Id : string){

        let url = environment.url_base + 'api/Maritime/'+Id;

        console.log('getMaritimeById Url : ' + url);
        return this.http.get(url).pipe(map(
            (response: Response) => {
                const maritimeModel: MaritimeModel = response.json();
                return maritimeModel;
            }
        ));
    }

    
    getAllClassifications(){

        let url = environment.url_base + 'api/Maritime/AllClassification';

        console.log('getAllClassifications Url : ' + url);
        return this.http.get(url).pipe(map(
            (response: Response) => {
                const maritimeClassificationModel: MaritimeClassificationModel[] = response.json();
                return maritimeClassificationModel;
            }
        ));
    }

    postNewClassification(classifName : string){
        let url = environment.url_base + 'api/Maritime/Classification';

        let classifModel = new MaritimeClassificationModel();
        classifModel.ClassificationName = classifName;
        const classifModelAsString:String = JSON.stringify(classifModel);

        console.log('postNewClassification Url : ' + url);
        
        const header = new Headers();
        header.append('Content-Type', 'application/json');
        return this.http.post(url, classifModelAsString, { headers: header }).pipe(map(
            (response: Response) => {
                const classificationModel: MaritimeClassificationModel = response.json();
                return classificationModel;
            }
        ));
    }

    PostNewShip(ShipName:string, MMSI:string, IMO:string, classificationId:string){
        let url = environment.url_base + 'api/Maritime/Ship';

        let marModel = new MaritimeLightModel();
        marModel.ShipName = ShipName;
        marModel.MMSI = MMSI;
        marModel.IMO = IMO;
        marModel.MARITIME_CLASSIFICATION_Id = classificationId;
        const marModelAsString:String = JSON.stringify(marModel);

        console.log('PostNewShip Url : ' + url,marModelAsString,ShipName);
        
        const header = new Headers();
        header.append('Content-Type', 'application/json');
        return this.http.post(url, marModelAsString, { headers: header }).pipe(map(
            (response: Response) => {
                const maritimeLightModel: MaritimeLightModel = response.json();
                return maritimeLightModel;
            }
        ));
    }

    getAllPavillons(){

        let url = environment.url_base + 'api/Maritime/AllPavillons';

        console.log('getAllPavillons Url : ' + url);
        return this.http.get(url).pipe(map(
            (response: Response) => {
                const pavillons: string[] = response.json();
                return pavillons;
            }
        ));
    }

    PostNewObservation(obsModel:MaritimeObservationModel){
        let url = environment.url_base + 'api/Maritime/Observation';
        
        const marObsModelAsString:String = JSON.stringify(obsModel);

         console.log('PostNewObservation Url : ' + url,marObsModelAsString);
        
        const header = new Headers();
        header.append('Content-Type', 'application/json');
        return this.http.post(url, marObsModelAsString, { headers: header }).pipe(map(
            (response: Response) => {
                const maritimeObservationModel: MaritimeObservationModel = response.json();
                return maritimeObservationModel;
            }
        ));
    }

    UpdateObservation(obsModel:MaritimeObservationModel){
        let url = environment.url_base + 'api/Maritime/Observation';
        
        const marObsModelAsString:String = JSON.stringify(obsModel);

         console.log('UpdateObservation Url : ' + url,marObsModelAsString);
        
        const header = new Headers();
        header.append('Content-Type', 'application/json');
        return this.http.patch(url, marObsModelAsString, { headers: header }).pipe(map(
            (response: Response) => {
                const maritimeObservationModel: MaritimeObservationModel = response.json();
                return maritimeObservationModel;
            }
        ));
    }

    DeleteObservation(obsModel:MaritimeObservationModel){
        let url = environment.url_base + 'api/Maritime/Observation/'+obsModel.Id;
        
        console.log('DeleteObservation Url : ' + url);
        
        const header = new Headers();
        header.append('Content-Type', 'application/json');
        return this.http.delete(url, { headers: header }).pipe(map(
            (response: Response) => {
                const retour: boolean = response.json();
                return retour;
            }
        ));
    }

    getMaritimeObservationPicturesByObservation(IdObservation : string){

        let url = environment.url_base + 'api/Maritime/Observation/Picture/'+IdObservation;

        console.log('getMaritimeObservationPicturesByObservation Url : ' + url);
        return this.http.get(url).pipe(map(
            (response: Response) => {
                const maritimeObsPictModels: MaritimeObservationPictureModel[] = response.json();
                return maritimeObsPictModels;
            }
        ));
    }

    getMaritimeObservationVideosByObservation(IdObservation : string){

        let url = environment.url_base + 'api/Maritime/Observation/Video/'+IdObservation;

        console.log('getMaritimeObservationVideosByObservation Url : ' + url);
        return this.http.get(url).pipe(map(
            (response: Response) => {
                const maritimeObsVideoModels: MaritimeObservationVideoModel[] = response.json();
                return maritimeObsVideoModels;
            }
        ));
    }

    DeletePicture(pictureModel:MaritimeObservationPictureModel){
        let url = environment.url_base + 'api/Maritime/Picture/'+pictureModel.Id;
        
        console.log('DeletePicture Url : ' + url);
        
        const header = new Headers();
        header.append('Content-Type', 'application/json');
        return this.http.delete(url, { headers: header }).pipe(map(
            (response: Response) => {
                return true;
            }
        ));
    }

    DeleteVideo(videoModel:MaritimeObservationVideoModel){
        let url = environment.url_base + 'api/Maritime/Video/'+videoModel.Id;
        
        console.log('DeleteVideo Url : ' + url);
        
        const header = new Headers();
        header.append('Content-Type', 'application/json');
        return this.http.delete(url, { headers: header }).pipe(map(
            (response: Response) => {
                return true;
            }
        ));
    }





    ConvertCoordDegMinSecToDecimal(degres:number, minutes:number, secondes:number, NordSud_EstOuest:string){
        console.log('ConvertCoordDegMinSecToDecimal',degres,minutes,secondes,NordSud_EstOuest);
        let degDecim = degres;
        degDecim += minutes/60;
        degDecim += secondes/3600;
        if(NordSud_EstOuest.startsWith('S') || NordSud_EstOuest.startsWith('O')){
            degDecim /= -1;
        }
        return degDecim;
    }

    ConvertCoordDecimalToDegMinSec(degresDecimaux:number){
        //console.log('degresDecimaux',degresDecimaux);
        let degresDecimauxAbs = Math.abs(degresDecimaux);
        let degree =  Math.floor(degresDecimauxAbs);
        //console.log('degree',degree);
        let minutes  =  Math.floor((degresDecimauxAbs-degree)*60);
        //console.log('minutes',minutes);
        let seconds = Math.round((degresDecimauxAbs - degree - (minutes/60)) * 3600);
        //console.log('seconds',seconds);
        return [degree,minutes,seconds];
    }

    getNordSudByDegDecimaux(degresDecimaux:number){
        if(degresDecimaux<0)
            return "S";
        else
            return "N"
    }

    getEstOuestByDegDecimaux(degresDecimaux:number){
        if(degresDecimaux<0)
            return "O";
        else
            return "E"
    }

    FromMartimeModelToMaritimeLightModel(maritime:MaritimeModel){
        let maritimeLight : MaritimeLightModel = new MaritimeLightModel();
        maritimeLight.IMO = maritime.IMO;
        maritimeLight.Id = maritime.Id;
        maritimeLight.MARITIME_CLASSIFICATION_Id = maritime.MARITIME_CLASSIFICATION_Id;
        maritimeLight.MARITIME_CLASSIFICATION_Name = maritime.MARITIME_CLASSIFICATION_Name;
        maritimeLight.MMSI = maritime.MMSI;
        maritimeLight.ShipName = maritime.ShipName;
        return maritimeLight;
    }

    FromMartimeModelLightToMaritimeModel(maritimeLight:MaritimeLightModel){
        let maritime : MaritimeModel = new MaritimeModel();
        maritime.IMO = maritimeLight.IMO;
        maritime.Id = maritimeLight.Id;
        maritime.MARITIME_CLASSIFICATION_Id = maritimeLight.MARITIME_CLASSIFICATION_Id;
        maritime.MARITIME_CLASSIFICATION_Name = maritimeLight.MARITIME_CLASSIFICATION_Name;
        maritime.MMSI = maritimeLight.MMSI;
        maritime.ShipName = maritimeLight.ShipName;
        return maritime;
    }


}