import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, Directive } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { SigninComponent } from './signin/signin.component';
import { HomeComponent } from './home/home.component';
import { AuthService } from './signin/auth.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { MaritimeContainerComponent } from './maritime/maritime-container/maritime-container.component';
import { MaritimeRechercheComponent } from './maritime/maritime-recherche/maritime-recherche.component';
import { MaritimeAjouterClassifComponent } from './maritime/maritime-ajouter-classif/maritime-ajouter-classif.component';
import { MaritimeDonneesComponent } from './maritime/maritime-donnees/maritime-donnees.component';
import { MaritimeObservationsComponent } from './maritime/maritime-observations/maritime-observations.component';
import { MaritimeFichiersComponent } from './maritime/maritime-fichiers/maritime-fichiers.component';
import { MaritimePhotoComponent } from './maritime/maritime-photo/maritime-photo.component';
import { MaritimeVideoComponent } from './maritime/maritime-video/maritime-video.component';
import { MatAutocompleteModule, MatFormFieldModule, MatInputModule, MatButtonModule, MatIconModule, MatTabsModule, MatDatepickerModule, MatNativeDateModule, MatCheckboxModule, MatRadioModule, MatButtonToggleModule, MatCardModule, MatGridListModule, MatDialog, MatDialogModule, MatSnackBarModule, MatSelectModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import { MaritimeService } from './maritime/maritime.service';
import { MaritimeDonneesCreerComponent } from './maritime/maritime-donnees-creer/maritime-donnees-creer.component';
import { MaritimeAjouterObservationComponent } from './maritime/maritime-ajouter-observation/maritime-ajouter-observation.component';
import { ConfirmYesNoComponent } from './common/confirm-yes-no/confirm-yes-no.component';
import { AngularFileUploaderModule } from "angular-file-uploader";


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SigninComponent,
    HomeComponent,
    MaritimeContainerComponent,
    MaritimeRechercheComponent,
    MaritimeAjouterClassifComponent,
    MaritimeDonneesComponent,
    MaritimeObservationsComponent,
    MaritimeFichiersComponent,
    MaritimePhotoComponent,
    MaritimeVideoComponent,
    MaritimeDonneesCreerComponent,
    MaritimeAjouterObservationComponent,
    ConfirmYesNoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatIconModule,
    MatTabsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatRadioModule,
    MatButtonToggleModule,
    MatCardModule,
    MatGridListModule,
    MatDialogModule,
    MatSnackBarModule,
    MatSelectModule,
    BrowserModule, 
    FormsModule, 
    BrowserAnimationsModule,
    AngularFileUploaderModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr' },
    AuthService,
    DatePipe,
    MaritimeService
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    MaritimeRechercheComponent,
    MaritimePhotoComponent,
    MaritimeVideoComponent,
    MaritimeAjouterClassifComponent,
    MaritimeAjouterObservationComponent,
    ConfirmYesNoComponent
  ]
})
export class AppModule { }
