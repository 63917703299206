import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-confirm-yes-no',
  templateUrl: './confirm-yes-no.component.html',
  styleUrls: ['./confirm-yes-no.component.css']
})
export class ConfirmYesNoComponent implements OnInit {

  @Input()
  message : string;

  constructor(public dialogRef: MatDialogRef<ConfirmYesNoComponent>,@Inject(MAT_DIALOG_DATA) public data: any) { 
    this.message = data.message;
  }

  ngOnInit() {
  }

  oui(){
    this.dialogRef.close(true);
  }

  non(){
    this.dialogRef.close(false);
  }

}
