import { Component, OnInit, Directive, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { MaritimePhotoComponent } from '../maritime-photo/maritime-photo.component';
import { MatDialog, MatSnackBar } from '@angular/material';
import { environment } from '../../../environments/environment.prod';
import { MaritimeObservationModel } from '../Models/MaritimeObservation.Model';
import { MaritimeObservationPictureModel } from '../Models/MaritimeObservationPicture.Model';
import { MaritimeService } from '../maritime.service';
import { ConfirmYesNoComponent } from '../../common/confirm-yes-no/confirm-yes-no.component';
import { MaritimeObservationVideoModel } from '../Models/MaritimeObservationVideo.Model';
import { MaritimeVideoComponent } from '../maritime-video/maritime-video.component';


@Component({
  selector: 'app-maritime-fichiers',
  templateUrl: './maritime-fichiers.component.html',
  styleUrls: ['./maritime-fichiers.component.css']
})

export class MaritimeFichiersComponent implements OnInit {

  @Input()
  observation : MaritimeObservationModel;

  groupTypeFichier : string;
  showPhoto = true;
  showVideo = false;

  URLPicture : string = environment.url_base + 'api/Maritime/Observation/Picture/';
  URLVideo : string = environment.url_base + 'api/Maritime/Observation/Video/';

  constructor(public dialog: MatDialog,public marServ : MaritimeService,public snackBar: MatSnackBar) { }

  ngOnInit() {
    this.URLPicture += this.observation.Id;
    this.URLVideo += this.observation.Id;
    this.afuConfigPicture.uploadAPI.url = this.URLPicture;
    this.afuConfigVideo.uploadAPI.url = this.URLVideo;
  }

  onTypeFichierChange(value :string){
    this.groupTypeFichier = value;
    if(value == "photo"){
      this.showVideo = false;
      this.showPhoto = true;
      // this.videoHeight = this.videoDiv.nativeElement.clientHeight;
      // console.log(this.videoDiv.nativeElement);
      // this.videoDiv.nativeElement.clientHeight = 0;
      // this.photoDiv.nativeElement.clientHeight = this.photoHeight;
    }
    else if(value == "video"){
      this.showVideo = true;
      this.showPhoto = false;
      // this.photoHeight = this.photoDiv.nativeElement.clientHeight;
      // console.log(this.photoDiv.nativeElement);
      // this.photoDiv.nativeElement.clientHeight = 0;
      // this.videoDiv.nativeElement.clientHeight = this.videoHeight;
    }
    console.log(value);
  }

  ouvrirPhoto(pictureUrl : string){

      let dialogRef = this.dialog.open(MaritimePhotoComponent, {
        width: '95%',
        data: {url : pictureUrl}
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          console.log('MaritimePhotoComponent closed');
        }
      })
  }

  ouvrirVideo(videoUrl : string){

    let dialogRef = this.dialog.open(MaritimeVideoComponent, {
      width: '740px',
      data: {url : videoUrl}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('MaritimeVideoComponent closed');
      }
    })
}

  supprimerPhoto(picture : MaritimeObservationPictureModel){

    let dialogRef = this.dialog.open(ConfirmYesNoComponent, {
      width: '300px',
      data: {message : "Etes-vous sûr de vouloir supprimer cette photo ?"}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.marServ.DeletePicture(picture).subscribe(
          (value: any) => {
            console.log('supprimer picture', value);
            this.observation.MARITIME_PICTUREModelSet.splice(this.observation.MARITIME_PICTUREModelSet.indexOf(picture),1);

            this.snackBar.open("Photo supprimée !", "", {
              duration: 3000,
            });
          }
        );
      }
      else{
        this.snackBar.open("Opération annulée !", "", {
          duration: 3000,
        });
      }
    });
}

supprimerVideo(video : MaritimeObservationVideoModel){

  let dialogRef = this.dialog.open(ConfirmYesNoComponent, {
    width: '300px',
    data: {message : "Etes-vous sûr de vouloir supprimer cette vidéo ?"}
  });

  dialogRef.afterClosed().subscribe(result => {
    if (result) {
      this.marServ.DeleteVideo(video).subscribe(
        (value: any) => {
          console.log('supprimer video', value);
          this.observation.MARITIME_VIDEOModelSet.splice(this.observation.MARITIME_VIDEOModelSet.indexOf(video),1);

          this.snackBar.open("Vidéo supprimée !", "", {
            duration: 3000,
          });
        }
      );
    }
    else{
      this.snackBar.open("Opération annulée !", "", {
        duration: 3000,
      });
    }
  });
}

  afuConfigPicture = {
    multiple : true,
    theme: "dragNDrop",
    formatsAllowed : ".jpg,.png,.jpeg",
    maxSize : 30,
    //hideSelectBtn:true,
    //hideResetBtn:true,
    uploadAPI: {
      url:this.URLPicture
    }
  }

  afuConfigVideo = {
    multiple : true,
    theme: "dragNDrop",
    formatsAllowed : ".mpeg,.mpg,.avi,.ogv,.3gp,.mp4",
    maxSize : 1000,
    //hideSelectBtn:true,
    //hideResetBtn:true,
    uploadAPI: {
      url:this.URLVideo
    }
  }

  PictureUpload(event:any)
  {
    console.log('PictureUpload',event);
    if(event.status == 200){
      this.updatePictures();
    }
  }

  VideoUpload(event:any)
  {
    console.log('VideoUpload',event);
    if(event.status == 200){
      this.updateVideos();
    }
  }

  updatePictures(){
     this.marServ.getMaritimeObservationPicturesByObservation(this.observation.Id).subscribe(
      (value: MaritimeObservationPictureModel[]) => {
        console.log('MaritimeObservationPictureModels', value);
        this.observation.MARITIME_PICTUREModelSet = value;
      }
    );
  }

  updateVideos(){
   this.marServ.getMaritimeObservationVideosByObservation(this.observation.Id).subscribe(
     (value: MaritimeObservationVideoModel[]) => {
       console.log('MaritimeObservationVideoModels', value);
       this.observation.MARITIME_VIDEOModelSet = value;
     }
   );
 }

}
