import { Injectable } from "@angular/core";
import { Http, Response } from "@angular/http";
import { Subject } from 'rxjs';


@Injectable()
export class AuthService {
    token: string = null;
    tokenChanged = new Subject();

    constructor(private http: Http) {
    }

    signin(username: string, password: string): boolean {

        let authResult: boolean;
        authResult = true;
        this.token = "ok";
        console.log(username,password);
        // if (username.length > 0 && password.length > 0) {
        //     const authUrl = environment.url_base + 'api/Agent/Login/' + username + '/' + password;
        //     this.http.get(authUrl).subscribe(
        //         (resp: Response) => {
        //             if (resp.ok) {
        //                 const data = resp.json();
        //                 console.log(data);

        //                 // Agent
        //                 this.agentSrv.changeCurrentAgent(data);
        //                 // Agent Accreditations
        //                 this.agentSrv.getAgentAccreditations().subscribe(
        //                     (value: any) => {
        //                         // Token
        //                         this.token = this.agentSrv.getCurrentAgent().Id;
                                this.tokenChanged.next();

        //                         authResult = true;
        //                     }
        //                 );
        //             } else {
        //                 authResult = false;
        //             }
        //         }
        //     );
        // }

        return authResult;
    }

    logout() {
        // this.token = null;
        // this.agentSrv.clearAgentServiceData();
        // this.tokenChanged.next();
    }

    isAuthenticated() {
         return this.token != null;
    }
}